<template>
  <span>
    <v-row
      v-if="competencia_de || competencia_ate"
      class="mx-auto"
      justify="center"
    >
      <div class="mt-2 body-1 font-weight-bold">
        Há
        <span class="error--text">{{ qtdIndicadoresErros }}</span> indicador(es)
        com erros de validação
      </div>
    </v-row>

    <v-row class="mx-auto" v-if="competencia_de || competencia_ate">
      <v-col cols="12" class="mt-2">
        <v-data-table
          multi-sort
          dense
          :headers="headers"
          :items="filteredItems"
          :options="{ multiSort: false }"
          loading-text="Carregando tabela"
          :headerProps="{ sortByText: 'Ordenar Por' }"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: 'Itens por Página',
            itemsPerPageOptions: [10, 25, 50, 100],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
          class="row-cursor-pointer mb-8"
          @click:row="handleClick"
        >
          <template v-slot:[`item.status_aprovacoes`]="{ item }">
            <span
              :class="
                {
                  NA: 'font-weight-medium',
                  Validado: 'success--text font-weight-bold',
                  'Em Análise': 'info--text font-weight-bold',
                  'Reprovado - Retificar': 'error--text font-weight-bold',
                }[item.status_aprovacoes]
              "
            >
              {{ item.status_aprovacoes }}
            </span>
          </template>
          <template
            v-for="validacao in listaValidacoes"
            v-slot:[getItemSlotName(validacao)]="{ item }"
          >
            <span :key="validacao">
              <v-chip
                v-if="item[`validos_${validacao}`] == undefined"
                small
                class="mb-1 mr-1"
              >
                <span class="font-weight-bold">NA</span>
              </v-chip>
              <template v-else>
                <v-chip
                  v-if="item[`validos_${validacao}`] > 0"
                  small
                  outlined
                  class="mb-1 mr-1"
                  color="success"
                >
                  <span class="mr-1 font-weight-black" left>
                    {{ item[`validos_${validacao}`] }}
                  </span>
                  OK
                </v-chip>
                <v-chip
                  v-if="item[`alertas_${validacao}`] > 0"
                  small
                  outlined
                  class="mb-1 mr-1"
                  color="warning"
                >
                  <span class="mr-1 font-weight-black" left>
                    {{ item[`alertas_${validacao}`] }}
                  </span>
                  Alertas
                </v-chip>
                <v-chip
                  v-if="item[`erros_criticos_${validacao}`] > 0"
                  small
                  class="mb-1 mr-1"
                  color="error"
                >
                  <span class="mr-1 font-weight-black" left>
                    {{ item[`erros_criticos_${validacao}`] }}
                  </span>
                  Erros
                </v-chip>
              </template>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <tecnicos-visao-detalhada-dialog-detalhes
      :competencia_de="competencia_de"
      :competencia_ate="competencia_ate"
      :authorizedCategoriesTecAl="authorizedCategoriesTecAl"
      :authorizedCategoriesTecSub="authorizedCategoriesTecSub"
      :authorizedCategoriesTecLd="authorizedCategoriesTecLd"
      :authorizedCompanies="authorizedCompanies"
      ref="dialogDetalhes"
      @updateLoadingDados="$emit('updateLoadingDados', $event)"
      @updateDataTable="$emit('updateDataTable')"
    />
  </span>
</template>

<script>
  import PainelTecnicosService from "@/services/PainelTecnicosService.js";

  export default {
    name: "TecnicosVisaoDetalhada",
    components: {
      TecnicosVisaoDetalhadaDialogDetalhes: () =>
        import(
          "@/components/validacoes/TecnicosVisaoDetalhadaDialogDetalhes.vue"
        ),
    },
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      authorizedFieldsTecAlCategories: {
        type: Array,
        required: false,
      },
      authorizedFieldsTecSubCategories: {
        type: Array,
        required: false,
      },
      authorizedFieldsTecLdCategories: {
        type: Array,
        required: false,
      },
      mostrarApenasErros: {
        type: Boolean,
        default: false,
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
    },
    data: () => ({
      listaValidacoes: [
        "qtd_consumidor",
        "qtd_reclamacao",
        "inspecao_visual",
        "inspecao_termografica",
        "manutencao_preventiva",
        "manutencao_corretiva",
      ],
      headers: [
        { text: "Base", value: "base" },
        { text: "Aprovações", value: "status_aprovacoes" },
        { text: "Quantidade consumidor", value: "qtd_consumidor" },
        { text: "Quantidade reclamação", value: "qtd_reclamacao" },
        { text: "Inspeção Visual", value: "inspecao_visual" },
        { text: "Inspeção Termográfica", value: "inspecao_termografica" },
        { text: "Manutenção Preventiva", value: "manutencao_preventiva" },
        { text: "Manutenção Corretiva", value: "manutencao_corretiva" },
      ],
      items: [],
      dialogDetalhes: false,
      dialogDetalhesSelected: {},
      dialogDetalhesHeader: [],
      dialogDetalhesData: [],
    }),
    computed: {
      filteredItems() {
        let items = JSON.parse(JSON.stringify(this.items));

        if (this.mostrarApenasErros == true) {
          items = items.filter((item) => item?.total_erros_criticos || 0 > 0);
        }

        return items;
      },
      qtdIndicadoresErros() {
        let qtdErros = 0;

        this.headers
          .map((el) => el.value)
          .forEach((indicador) => {
            this.items.forEach((item) => {
              qtdErros += item?.[`erros_criticos_${indicador}`] || 0;
            });
          });

        return qtdErros;
      },
      authorizedCategoriesTecAl() {
        let fields = [];
        this.authorizedFieldsTecAlCategories.forEach((category) => {
          fields.push(category.slug);
        });

        return fields;
      },
      authorizedCategoriesTecSub() {
        let fields = [];
        this.authorizedFieldsTecSubCategories.forEach((category) => {
          fields.push(category.slug);
        });

        return fields;
      },
      authorizedCategoriesTecLd() {
        let fields = [];
        this.authorizedFieldsTecLdCategories.forEach((category) => {
          fields.push(category.slug);
        });

        return fields;
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate
      ) {
        this.$emit("updateLoadingDados", true);

        return PainelTecnicosService.getQtdTiposValidacao(
          competencia_de,
          competencia_ate,
          this.$store.getters.getUserId,
          this.authorizedCategoriesTecAl.join(","),
          this.authorizedCategoriesTecSub.join(","),
          this.authorizedCategoriesTecLd.join(",")
        )
          .then((res) => {
            this.items = res;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      downloadArquivoValidacoes() {
        this.$emit("updateDownloadingArquivoValidacoes", true);

        this.$toast.info(
          "Preparando seu arquivo para download, essa etapa pode demorar, por favor, aguarde",
          "",
          {
            position: "topRight",
            timeout: 6000,
          }
        );

        PainelTecnicosService.downloadArquivoValidacoes(
          this.competencia_de,
          this.competencia_ate,
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let nomeArquivo = await this.getNomeArquivoValidacoes();
            link.setAttribute("download", `${nomeArquivo}.xlsx`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() =>
            this.$emit("updateDownloadingArquivoValidacoes", false)
          );
      },
      getNomeArquivoValidacoes() {
        return PainelTecnicosService.getNomeArquivoValidacoes()
          .then((res) => res.data.nome)
          .catch((err) => {
            this.$toast.error("Erro ao recuperar nome do arquivo.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getHeaderSlotName(name) {
        return `header.${name}`;
      },
      getItemSlotName(indicador) {
        return `item.${indicador}`;
      },
      handleClick(items) {
        this.$refs.dialogDetalhes.getDados(items.base);
      },
    },
  };
</script>

<style></style>

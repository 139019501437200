var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.competencia_de || _vm.competencia_ate)?_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"center"}},[_c('div',{staticClass:"mt-2 body-1 font-weight-bold"},[_vm._v(" Há "),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.qtdIndicadoresErros))]),_vm._v(" indicador(es) com erros de validação ")])]):_vm._e(),(_vm.competencia_de || _vm.competencia_ate)?_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-cursor-pointer mb-8",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.filteredItems,"options":{ multiSort: false },"loading-text":"Carregando tabela","headerProps":{ sortByText: 'Ordenar Por' },"hide-default-footer":"","footer-props":{
          itemsPerPageText: 'Itens por Página',
          itemsPerPageOptions: [10, 25, 50, 100],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.status_aprovacoes",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:{
                NA: 'font-weight-medium',
                Validado: 'success--text font-weight-bold',
                'Em Análise': 'info--text font-weight-bold',
                'Reprovado - Retificar': 'error--text font-weight-bold',
              }[item.status_aprovacoes]},[_vm._v(" "+_vm._s(item.status_aprovacoes)+" ")])]}},_vm._l((_vm.listaValidacoes),function(validacao){return {key:_vm.getItemSlotName(validacao),fn:function(ref){
              var item = ref.item;
return [_c('span',{key:validacao},[(item[("validos_" + validacao)] == undefined)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("NA")])]):[(item[("validos_" + validacao)] > 0)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":"","outlined":"","color":"success"}},[_c('span',{staticClass:"mr-1 font-weight-black",attrs:{"left":""}},[_vm._v(" "+_vm._s(item[("validos_" + validacao)])+" ")]),_vm._v(" OK ")]):_vm._e(),(item[("alertas_" + validacao)] > 0)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":"","outlined":"","color":"warning"}},[_c('span',{staticClass:"mr-1 font-weight-black",attrs:{"left":""}},[_vm._v(" "+_vm._s(item[("alertas_" + validacao)])+" ")]),_vm._v(" Alertas ")]):_vm._e(),(item[("erros_criticos_" + validacao)] > 0)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":"","color":"error"}},[_c('span',{staticClass:"mr-1 font-weight-black",attrs:{"left":""}},[_vm._v(" "+_vm._s(item[("erros_criticos_" + validacao)])+" ")]),_vm._v(" Erros ")]):_vm._e()]],2)]}}})],null,true)})],1)],1):_vm._e(),_c('tecnicos-visao-detalhada-dialog-detalhes',{ref:"dialogDetalhes",attrs:{"competencia_de":_vm.competencia_de,"competencia_ate":_vm.competencia_ate,"authorizedCategoriesTecAl":_vm.authorizedCategoriesTecAl,"authorizedCategoriesTecSub":_vm.authorizedCategoriesTecSub,"authorizedCategoriesTecLd":_vm.authorizedCategoriesTecLd,"authorizedCompanies":_vm.authorizedCompanies},on:{"updateLoadingDados":function($event){return _vm.$emit('updateLoadingDados', $event)},"updateDataTable":function($event){return _vm.$emit('updateDataTable')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }